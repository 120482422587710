:root {
  --primary-green: #388438;
  --action-button-text: #fff;
  --background-white: #fff;
  --error-red: #c00f00;
  --primary-border-grey: #cecece;
  --primary-sub-text-grey: #909090;
  --primary-text-black: #222;
  --primary-background-grey: #E5E5E5;
}

body {
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

body {
  background: transparent;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

#root {
  display: flex;
  height: 100vh;
  max-width: 100vw;
}



.container :global .button-container-style {
  margin: 1rem 0;
  width: 100%;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 8px;
}

::-webkit-scrollbar:hover {
  border: 1px solid #B2B2B2;
}

::-webkit-scrollbar-thumb:hover {
  background: #7e7b7b;
}